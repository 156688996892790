import $ from 'jquery';

$(function() {
    
    // Navbar hover class for desktop
    $('#navbar-menu > li').hover(
        function () {
            if ($(window).width() > 1024) {
                $(this).addClass('over');
            }
        },
        function () {
            $(this).removeClass('over');
        }
    );

    // Toggle Mobile Subnav items
    $('.navbar-nav .dropdown-toggle').on('click', function () {
        if (
            $(this)
                .parent('.menu-item-has-children')
                .hasClass('mobile-subnav-open')
        ) {
            $(this).siblings('.dropdown-menu').slideUp(300);
        } else {
            $(this).siblings('.dropdown-menu').slideDown(300);
        }
        $(this)
            .parent('.menu-item-has-children')
            .toggleClass('mobile-subnav-open');
    });

    // Check if card grid item has link
    $('.card-grid_content a').each(function () {
        $(this).parents('.card-grid_content').addClass('has-link');
    });

    // Toggle Accordion items
    $('.accordion_question').on('click', function () {
        if ($(this).parent('.accordion_item').hasClass('faq-open')) {
            $(this).siblings('.accordion_answer').slideUp(200);
        } else {
            $(this).siblings('.accordion_answer').slideDown(200);
        }
        $(this).parent('.accordion_item').toggleClass('faq-open');
    });

    // Mobile menu toggle
    $('#toggle').on('click', function (event) {
        event.preventDefault();

        $(this).toggleClass('is-open');
        $('header.header-main').toggleClass('mobile-nav-open');
    });

    // Check Mobile Menu display
    function checkMenuDisplay() {
        if ($(window).width() > 1024) {
            $('#toggle').removeClass('is-open');
            $('header.header-main').removeClass('mobile-nav-open');
        } else if ($(window).width() <= 1024) {
            $('#navbar-menu > li').removeClass('mobile-subnav-open');
        }
    }

    checkMenuDisplay();

    $(window).resize(function () {
        checkMenuDisplay();
    });
});